/*
 * @Description: apis
 * @Author: yaoxiaoxiao
 * @RearEnd: 郑晓
 * @Date: 2021-03-30 10:28:40
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-04-01 16:48:48
 */
import service from '@/utils/request'

// 主列表
const LIST_PATH = '/admin/log/getPageOfLog'
// 操作模块列表
const OPERATION_MENU_PATH = '/admin/log/getListOfOperationMenu'
// 操作类型列表
const OPERATION_TYPE_PATH = '/admin/log/getListOfOperationType'

/**
* @description: 列表分页查询
* @param {any} data
* @return {object}
*/
export const getListAPI = (data: any): object => {
  return service.request({
    url: LIST_PATH,
    method: 'post',
    data
  })
}

/**
* @description: 操作模块列表
* @param {any} data
* @return {object}
*/
export const operationMenuAPI = (data: any): object => {
  return service.request({
    url: OPERATION_MENU_PATH,
    method: 'post',
    data
  })
}

/**
* @description: 操作类型列表
* @param {any} data
* @return {object}
*/
export const operationTypeAPI = (data: any): object => {
  return service.request({
    url: OPERATION_TYPE_PATH,
    method: 'post',
    data
  })
}
