
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { defineComponent, reactive, toRefs } from 'vue'
import { getTableConfig, inputList } from './config'
import moment from 'moment'
import { toResult } from '@/utils/toResult'
import { usePagination } from '@/hooks/usePagination'
// import { getListAPI, operationMenuAPI, operationTypeAPI } from './mock'
import { getListAPI, operationMenuAPI, operationTypeAPI } from './api'

export default defineComponent({
  components: { CSearchList, CTable },
  setup() {
    // 组件全局响应状态
    const state = reactive({
      inputList: inputList(),
      dataSource: getTableConfig(),
      searchParams: null
    })

    /**
       * @description: 异步获取操作模块列表
       * @param {*}
       * @return {*} Promise<void>
       */
    ;(async (): Promise<void> => {
      const [err, data] = await toResult(operationMenuAPI(null))
      if (err) return
      state.inputList[0].options = data.map((i: { operationMenu: string }) => ({
        label: i.operationMenu,
        value: i.operationMenu
      }))
    })()

    /**
     * @description: 异步获取操作类型列表
     * @param {*}
     * @return {*} Promise<void>
     */
    ;(async (): Promise<void> => {
      const [err, data] = await toResult(operationTypeAPI(null))
      if (err) return
      state.inputList[1].options = data.map((i: { operateType: string }) => ({
        label: i.operateType,
        value: i.operateType
      }))
    })()

    /**
     * @description: 异步获取列表数据
     * @param {*}
     * @return {void}
     */
    const getList = async (params: object): Promise<void> => {
      state.dataSource.loading = true
      const p = {
        currPage: state.dataSource.pagination.currentPage,
        pageSize: state.dataSource.pagination.pageSize,
        ...params
      }
      const [err, data] = await toResult(getListAPI(p))
      state.dataSource.loading = false
      if (err) return
      state.dataSource.data = data.list || []
      state.dataSource.pagination.total = data.total || 0
    }

    /**
     * @description: 执行搜索
     * @param {object} params
     * @return {void}
     */
    const handleSearch = (params: { createTime?: any[] } | null): void => {
      state.dataSource.pagination.currentPage = 1
      state.searchParams = {
        ...params,
        beginTime: params?.createTime
          ? moment(params.createTime[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endTime: params?.createTime
          ? moment(params.createTime[1]).format('YYYY-MM-DD HH:mm:ss')
          : ''
      }
      params?.createTime && delete state.searchParams.createTime
      state.searchParams?.beginTime === '' &&
        delete state.searchParams.beginTime
      state.searchParams?.endTime === '' && delete state.searchParams.endTime

      getList(state.searchParams)
    }

    // setup 时候执行一次
    handleSearch(null)

    /**
     * @description: 执行重置
     * @param {object} params
     * @return {void}
     */
    const handleReset = (params: object): void => {
      state.searchParams = params
    }

    // c-table pagination method hook
    const { pageCurrentChange, pageSizeChange } = usePagination(
      state.dataSource.pagination,
      () => {
        getList(state.searchParams)
      }
    )

    return {
      ...toRefs(state),
      pageSizeChange,
      pageCurrentChange,
      handleSearch,
      handleReset
    }
  }
})
