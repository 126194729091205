import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_search_list = _resolveComponent("c-search-list")
  const _component_c_table = _resolveComponent("c-table")
  const _directive_hasAuth = _resolveDirective("hasAuth")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_c_search_list, {
      inputList: _ctx.inputList,
      onOnSearch: _ctx.handleSearch,
      onOnReset: _ctx.handleReset
    }, null, 8, ["inputList", "onOnSearch", "onOnReset"]), [
      [_directive_hasAuth, 'log-query']
    ]),
    _createVNode(_component_c_table, {
      dataSource: _ctx.dataSource,
      onPageCurrentChange: _ctx.pageCurrentChange,
      onPageSizeChange: _ctx.pageSizeChange
    }, null, 8, ["dataSource", "onPageCurrentChange", "onPageSizeChange"])
  ]))
}