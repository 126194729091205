
// 搜索配置
export const inputList = () => [
  {
    type: 'select',
    label: '操作模块',
    key: 'operationMenu',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: []
  }, {
    type: 'select',
    label: '操作类型',
    key: 'operationType',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: []
  }, {
    type: 'input',
    label: '操作用户',
    key: 'nameOrPhone',
    placeholder: '请输入姓名或手机号',
    labelWidth: 100,
    inputWidth: 200,
    maxlength: 20,
    showWordLimit: true
  }, {
    type: 'datePicker',
    label: '日期时间范围',
    key: 'createTime',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 390,
    format: 'YYYY-MM-DD HH:mm:ss',
    // 设置禁用状态，参数为当前日期，要求返回 Boolean
    disabledDate: (time) => {
      return time.getTime() > Date.now()
    },
    // 日期类型 year/month/date/dates/ week/datetime/datetimerange/ daterange/monthrange
    dateType: 'datetimerange',
    // 开启日期范围后生效
    unlinkPanels: false,
    rangeSeparator: '-',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    rules: {
      // 默认 date 开启范围 array
      type: 'array'
    }
  }
]

// 表格配置
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: false,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '编号',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'operationContent',
      label: '操作内容',
      width: '250'
    },
    {
      key: 'operationMenu',
      label: '操作模块',
      width: '120'
    },
    {
      key: 'operationType',
      label: '操作类型',
      width: '120'
    },
    {
      key: 'creatorNameAndPhone',
      label: '操作用户',
      width: '120'
    },
    {
      key: 'dataStatus',
      label: '数据状态',
      tooltip: true,
      width: '300'
    },
    {
      key: 'createTime',
      label: '操作时间',
      width: '150'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
